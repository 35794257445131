<template>
  <div class="error-container relative">
    <div class="middle">
      <div class="text-sxl text-bold-800" :class="fadeIn">糟糕! 找不到網頁</div>
      <div class="margin-top text-xs"></div>
    </div>
  </div>
</template>

<script>
import mainCommon from "@/utils/mainCommon";

export default {
  mixins: [mainCommon],
  created() {
    setTimeout(() => this.$router.push({ path: "/" }), 3000);
  },
};
</script>

<style lang="less" scoped>
.error-container {
  width: 100vw;
  height: calc(100vh - 105px);
}
</style>